html,
body {
  height: 100%;
  position: relative;
}

#page-container {
  position: relative;
  min-height: 100vh;
  display: block;
  padding-bottom: 60px;
  overflow: hidden;
}

.container {
  margin-top: 30px;
}

.navbar-nav {
  width: 100%;
  justify-content: space-between;
}

/* BUTTONS */
.btn-update {
  float: right;
  margin-bottom: 1rem;
  background-color: #009AC9;
  color: #fff !important;
  border-radius: 0;
  height: 2.5rem;
  width: 9rem;
  font-size: 1rem;
}

.btn-left {
  float: left;
  margin-bottom: 1rem;
  background-color: #009AC9;
  color: #fff !important;
  border-radius: 0;
  height: 2.5rem;
  width: 9rem;
  font-size: 1rem;
}

.btn-right {
  float: right;
  margin-bottom: 1rem;
  background-color: #009AC9;
  color: #fff !important;
  border-radius: 0;
  height: 2.5rem;
  width: 9rem;
  font-size: 1rem;
}

.btn:focus,
.btn:active {
  outline: none;
  box-shadow: none;
}

.nameBtn {
  color: #fff;
  padding: 0;
}

.nameBtn:hover {
  color: #fff;
}

.btn-nav {
  background-color: #FBCCCD;
  color: #231F20;
  border-radius: 0;
  height: 2.5rem;
  width: 9rem;
  font-size: 1rem;
  padding: 0;
}

.leftBtn,
.rightBtn {
  border: none;
  font-size: 1.5rem;
}

/* TABLE */
table {
  table-layout: fixed;
  margin: auto;
}

.table-hover tbody tr:hover {
  background-color: #FBCCCD;
}

thead {
  background-color: #231F20;
  color: #fff;
}

@media only screen and (min-width: 2000px) {
  .table-responsive {
    overflow-x: visible;
  }
}

#absencesHeader {
  text-align: center;
}

#absencesTypes {
  background-color: #231F20;
  font-size: 0.9rem;
}

.monthPicker {
  font-size: 1.5rem;
  font-family: Riposte-Bold;
  padding: 5px;
}

.monthAndYear {
  text-align: center;
}

.dataUpdated {
  text-align: right;
  line-height: 2.5rem;
  font-size: 0.9rem;
}

.dataUpdated p {
  margin-bottom: 0;
}

.arrowLeft {
  text-align: right;
  padding: 0;
}

.arrowRight {
  text-align: left;
  padding: 0;
}

/* NAVIGATION */
nav {
  background-color: #fff;
  padding: 0.5rem 0 0.5rem 0 !important;
}

#navLogo {
  height: 22px;
}

.balanceLink,
.reportsLink {
  font-family: Riposte-Bold;
  display: inline;
  font-size: 1.2em;
  border-bottom:  #009AC9 solid 3px;
  padding-bottom: 2px;
}

.balanceLinkBtn,
.reportsLinkBtn {
  font-family: Riposte-Bold;
  display: inline;
  font-size: 1.2em;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  color: #231F20 !important;
}

.balanceLinkBtn:focus,
.reportsLinkBtn:focus {
  outline: none;
  box-shadow: none;
}

/* FOOTER */
footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  height: 60px;
  background-color: #231F20;
}

footer p {
  color: #fff;
  margin-bottom: 0;
}

footer .container {
  margin-top: 20px;
}

/* FA ICONS */
.faIconSort {
  margin-left: 0.5rem;
}

/*TYPOGRAPHY*/
#page-container {
  font-family: Riposte-Regular;
  color: #231F20;
}

h1,
h2,
h3,
h4 {
  font-family: Riposte-Bold;
  color: #231F20;
}

.icon-container {
  padding-left: 2em;
  display: inline;
}

.icon:hover {
  color: #fd7e14;
  cursor: pointer;
}

.icon-row {
  text-align: center;
}

@media only screen and (min-width : 1430px) {
  .container {
    max-width: 1480px;
    padding: 0px 100px 0px 100px;
  }
}




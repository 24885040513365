
@font-face {
  font-family: 'Riposte-Bold';
  src: local('Riposte-Bold'), url(./fonts/Riposte-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Riposte-Regular';
  src: local('Riposte-Regular'), url(./fonts/Riposte-Regular.otf) format('opentype');
}

